import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import './App.css';

import { Home } from "./components/Home";
import { PageA } from "./components/PageA";
import { PageB } from "./components/PageB";

function App() {
  return (
    <div className="App">
      This is index
      <Router>
        <Route exact path="/" component={Home} />
        <Route path="/PageA" component={PageA} />
        <Route path="/PageB" component={PageB} />
      </Router>
    </div>
  );
}

export default App;
