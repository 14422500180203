import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export const Home = (props) =>{   
    return(
        <div>
            <h1>Home</h1>
            <ul>
                <li><Link to={"/"}>Home</Link></li>
                <li><Link to={"/PageA"}>Page A</Link></li>
                <li><Link to={"/PageB"}>Page B</Link></li>
            </ul>
        </div>
    );
}

